const routes = {
    home: '/',
    iphone: '/iphone',
    iphone14series: '/iphone14series',
    iphone13series: '/iphone13series',
    iphone12series: '/iphone12series',
    iphone11series: '/iphone11series',
    iphoneSE: '/iphoneSE',
    ipad: '/ipad',
    ipadprom1: '/ipad_pro_m1',
    ipadprom2: '/ipad_pro_m2',
    ipadair: '/ipad_air',
    ipad9: 'ipad_9',
    ipad10: 'ipad_10',
    ipadmini: 'ipad_mini',
    mac: '/mac',
    macbookpro: '/macbook_pro',
    macbookair: '/macbook_air',
    imac: '/imac',
    macmini: '/mac_mini',
    macpro: '/mac_pro',
    macstudio: '/mac_studio',
    watch: '/watch',
    applewatchultra: '/apple_watch_ultra',
    applewatchseries8: '/apple_watch_series_8',
    applewatchseries7: '/apple_watch_series_7',
    applewatchseries6: '/apple_watch_series_6',
    applewatchse: '/apple_watch_se',
    applewatchseries3: '/apple_watch_series_3',
    soundpage: '/soundpage',
    airpods: '/airpods',
    airpods_pro: '/airpods_pro',
    earpods: '/earpods',
    marshall: '/marshall',
    beats: '/beats',
    harman_kardon: '/harman_kardon',
    jbl: '/jbl',
    google: '/google',
    audio_technica: '/audio_technica',
    jabra: '/jabra',
    servicepage: '/servicepage',
    accessorypage: '/accessorypage',
    cuong_luc_bao_ve: '/cuong_luc_bao_ve',
    sac_cap: '/sac_cap',
    bao_da_op_lung: '/bao_da_op_lung',
    sac_du_phong: '/sac_du_phong',
    balo_tui_chong_soc: '/balo_tui_chong_soc',
    chuot_ban_phim: '/chuot_ban_phim',
    but_apple_pencil: '/but_apple_pencil',
    day_deo_apple_watch: '/day_deo_apple_watch',
    air_tags: '/air_tags',
    may_anh: '/may_anh',
    may_doc_sach: '/may_doc_sach',
    apple_TV: '/apple_TV',
    dong_ho_garmin: '/dong_ho_garmin',
    productitem: '/:namePath/:category',
    cart: '/cart',
    register: '/register',
    login: '/login',
    user: '/user',
    account: '/account',
    orderHistory: '/order_history',
    deliveryAddress: '/delivery_address',
    favoriteProducts: '/favorite_products',
    giftYour: '/gift_your',
};

export default routes;
